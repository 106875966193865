import React, { Component } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../css/FlightDetails.css';

function withNavigate(Component) {
  return function WrappedComponent(props) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  };
}

class FlightSearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: null,
      flightResults: [],
      error: null,
      flightSearchData: null, // To hold form data from localStorage
      currentPage: 1,
      resultsPerPage: 20,
      discountPercentage: 0,
      flightCount: 1
    };
  }

  // Function to apply the discount and update the state and local storage
applyDiscountToOffers = (offers) => {
  console.log("discount meth offers===",offers)
  const { discountPercentage } = this.state;

  const discountedOffers = offers.map((offer) => {
    const discountedPrice = this.calculateDiscountedPrice(parseFloat(offer.price.total));
    return {
      ...offer,
      discountedPrice, // Add the discounted price
    };
  });

  // Update the state and local storage
  this.setState({ flightResults: discountedOffers });
  localStorage.setItem('flightResults', JSON.stringify(discountedOffers));

  return discountedOffers; // Return the updated offers for further use
};

// Function to calculate the discounted price
calculateDiscountedPrice = (originalPrice) => {
  const { discountPercentage } = this.state;
  const discountedPrice = originalPrice - (originalPrice * discountPercentage) / 100;
  return discountedPrice.toFixed(2); // Ensure two decimal places
};

    // Method to fetch the access token
    fetchAccessToken = async () => {
      const clientId = '2IJdK0sFfScCqJzn7Sm7sVugAEgZmLAj'; // Replace with your Amadeus client ID
      const clientSecret = 'JTYluqbUfhF9lcOa'; // Replace with your Amadeus client secret
  
      try {
        const response = await axios.post(
          'https://test.api.amadeus.com/v1/security/oauth2/token',
          `grant_type=client_credentials&client_id=${clientId}&client_secret=${clientSecret}`,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
        this.setState({ accessToken: response.data.access_token }, this.fetchFlightOffers);
      } catch (error) {
        this.setState({ error: 'Failed to fetch access token' });
        console.error('Error fetching access token:', error);
      }
    };
  
    // Method to fetch flight offers
    // fetchFlightOffers = async () => {
    //   const { accessToken, flightSearchData } = this.state;

    //   if (!flightSearchData) return;
    //   const url = 'https://test.api.amadeus.com/v2/shopping/flight-offers';
    //   const params = {
    //     // originLocationCode: 'LON',
    //     // destinationLocationCode: 'JNB',
    //     // departureDate: '2024-12-23',
    //     // returnDate: '2024-12-30',
    //     // adults: 1,
    //     // travelClass: 'ECONOMY',
    //     // nonStop: false,
    //     // max: 250,
    //     originLocationCode: flightSearchData.from,
    //     destinationLocationCode: flightSearchData.to,
    //     departureDate: flightSearchData.start.toISOString().split('T')[0],
    //     returnDate: flightSearchData.return.toISOString().split('T')[0],
    //     adults: flightSearchData.adults,
    //     children: flightSearchData.child,
    //     infants: flightSearchData.infants,
    //     travelClass: flightSearchData.class.toUpperCase(),
    //     nonStop: false,
    //     max: 100,
    //     currencyCode: "GBP"
    //   };
  
    //   try {
    //     const response = await axios.get(url, {
    //       headers: {
    //         Authorization: `Bearer ${accessToken}`,
    //       },
    //       params: params,
    //     });

        
    //     console.log("flight api response===",response.data)
    //     console.log("response count====",response.data.meta.count)
    //     this.setState({flightCount: response.data.meta.count})
    //     const { data, dictionaries } = response.data;

    //   // Filter out "AMADEUS SIX" and map the results
    //   const flightResults = data
    //     .filter((offer) => offer.validatingAirlineCodes[0] !== '6X') // Exclude AMADEUS SIX
    //     .map((offer) => {
    //       const carrierCode = offer.validatingAirlineCodes[0];
    //       const actualCarrierName = dictionaries.carriers[carrierCode] || carrierCode;

    //       const updatedItineraries = offer.itineraries.map((itinerary) => ({
    //         ...itinerary,
    //         segments: itinerary.segments.map((segment) => ({
    //           ...segment,
    //           carrierName: dictionaries.carriers[segment.carrierCode] || segment.carrierCode,
    //         })),
    //       }));

    //       return {
    //         ...offer,
    //         carrierName: actualCarrierName,
    //         itineraries: updatedItineraries,
    //         price: offer.price,
    //       };
    //     });
    //     this.applyDiscountToOffers(flightResults);
    //     console.log("flightResults final===",flightResults)
    //   //this.setState({ flightResults });
    //   } catch (error) {
    //     this.setState({ error: 'Failed to fetch flight offers' });
    //     console.error('Error fetching flight offers:', error);
    //   }
    // };
    // fetchFlightOffers = async () => {
    //   const { accessToken, flightSearchData } = this.state;
    
    //   if (!flightSearchData) return;
    //   const url = 'https://test.api.amadeus.com/v2/shopping/flight-offers';
    //   const params = {
    //     originLocationCode: flightSearchData.from,
    //     destinationLocationCode: flightSearchData.to,
    //     departureDate: flightSearchData.start.toISOString().split('T')[0],
    //     returnDate: flightSearchData.return.toISOString().split('T')[0],
    //     adults: flightSearchData.adults,
    //     children: flightSearchData.child,
    //     infants: flightSearchData.infants,
    //     travelClass: flightSearchData.class.toUpperCase(),
    //     nonStop: false,
    //     max: 100,
    //     currencyCode: "GBP",
    //   };
    
    //   try {
    //     const response = await axios.get(url, {
    //       headers: {
    //         Authorization: `Bearer ${accessToken}`,
    //       },
    //       params: params,
    //     });
    
    //     console.log("flight api response===", response.data);
    //     console.log("response count====", response.data.meta.count);
    //     this.setState({ flightCount: response.data.meta.count });
    
    //     const { data, dictionaries } = response.data;
    
    //     // Filter out "AMADEUS SIX" and apply the requirement to show max two results per airline/carrier
    //     const flightResults = data
    //       .filter((offer) => offer.validatingAirlineCodes[0] !== '6X') // Exclude AMADEUS SIX
    //       .reduce((acc, offer) => {
    //         const carrierCode = offer.validatingAirlineCodes[0];
    //         const price = parseFloat(offer.price.total);
    
    //         // Ensure a maximum of two results per carrier with unique prices
    //         if (!acc[carrierCode]) {
    //           acc[carrierCode] = [offer];
    //         } else {
    //           const existingPrices = acc[carrierCode].map((o) => parseFloat(o.price.total));
    //           if (!existingPrices.includes(price) && acc[carrierCode].length < 2) {
    //             acc[carrierCode].push(offer);
    //           }
    //         }
    
    //         return acc;
    //       }, {});
    
    //     const filteredFlightResults = Object.values(flightResults).flat();
    
    //     // Map and update dictionaries for filtered results
    //     const finalFlightResults = filteredFlightResults.map((offer) => {
    //       const carrierCode = offer.validatingAirlineCodes[0];
    //       const actualCarrierName = dictionaries.carriers[carrierCode] || carrierCode;
    
    //       const updatedItineraries = offer.itineraries.map((itinerary) => ({
    //         ...itinerary,
    //         segments: itinerary.segments.map((segment) => ({
    //           ...segment,
    //           carrierName: dictionaries.carriers[segment.carrierCode] || segment.carrierCode,
    //         })),
    //       }));
    
    //       return {
    //         ...offer,
    //         carrierName: actualCarrierName,
    //         itineraries: updatedItineraries,
    //         price: offer.price,
    //       };
    //     });
    
    //     this.applyDiscountToOffers(finalFlightResults);
    //     console.log("Final filtered flight results:", finalFlightResults);
    //     // Update state or perform further actions with `finalFlightResults`
    //   } catch (error) {
    //     this.setState({ error: 'Failed to fetch flight offers' });
    //     console.error('Error fetching flight offers:', error);
    //   }
    // };
    
    fetchFlightOffers = async () => {
      const { accessToken, flightSearchData } = this.state;
    
      if (!flightSearchData) return;
    
      const url = 'https://test.api.amadeus.com/v2/shopping/flight-offers';
      const params = {
        originLocationCode: flightSearchData.from,
        destinationLocationCode: flightSearchData.to,
        departureDate: flightSearchData.start.toISOString().split('T')[0],
        returnDate: flightSearchData.return.toISOString().split('T')[0],
        adults: flightSearchData.adults,
        children: flightSearchData.child,
        infants: flightSearchData.infants,
        travelClass: flightSearchData.class.toUpperCase(),
        nonStop: false,
        max: 100,
        currencyCode: "GBP",
      };
    
      try {
        this.setState({ error: null }); // Clear previous errors
    
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: params,
        });
    
        console.log("Flight API response:", response.data);
    
        const { data, dictionaries } = response.data;
    
        // Filter and organize flight results
        const flightResults = data
          .filter((offer) => offer.validatingAirlineCodes[0] !== '6X') // Exclude AMADEUS SIX
          .reduce((acc, offer) => {
            const carrierCode = offer.validatingAirlineCodes[0];
            const price = parseFloat(offer.price.total);
    
            // Ensure a maximum of two results per carrier with unique prices
            if (!acc[carrierCode]) {
              acc[carrierCode] = [offer];
            } else {
              const existingPrices = acc[carrierCode].map((o) => parseFloat(o.price.total));
              if (!existingPrices.includes(price) && acc[carrierCode].length < 2) {
                acc[carrierCode].push(offer);
              }
            }
    
            return acc;
          }, {});
    
        const filteredFlightResults = Object.values(flightResults).flat();
    
        // Map and update dictionaries for filtered results
        const finalFlightResults = filteredFlightResults.map((offer) => {
          const carrierCode = offer.validatingAirlineCodes[0];
          const actualCarrierName = dictionaries.carriers[carrierCode] || carrierCode;
    
          const updatedItineraries = offer.itineraries.map((itinerary) => ({
            ...itinerary,
            segments: itinerary.segments.map((segment) => ({
              ...segment,
              carrierName: dictionaries.carriers[segment.carrierCode] || segment.carrierCode,
              departure: {
                ...segment.departure,
                date: new Date(segment.departure.at).toLocaleDateString(),
                time: new Date(segment.departure.at).toLocaleTimeString(),
              },
              arrival: {
                ...segment.arrival,
                date: new Date(segment.arrival.at).toLocaleDateString(),
                time: new Date(segment.arrival.at).toLocaleTimeString(),
              },
            })),
          }));
    
          return {
            ...offer,
            carrierName: actualCarrierName,
            itineraries: updatedItineraries,
            price: offer.price,
          };
        });
    
        // Apply discounts if required
        this.applyDiscountToOffers(finalFlightResults);
    console.log("check disc obj===",this.applyDiscountToOffers(finalFlightResults))
        console.log("Final filtered flight results:", finalFlightResults);
    
        // Update state with flight results for rendering
        this.setState({
          //flightResults: finalFlightResults,
          flightCount: response.data.meta.count,
        });
      } catch (error) {
        this.setState({ error: 'Failed to fetch flight offers' });
        console.error('Error fetching flight offers:', error);
      }
    };

    
    fetchDiscountValue = async () => {
      try {
        const response = await axios.get('https://api.uktravelroute.co.uk/discount'); // Endpoint to fetch discount
        const discountValue = parseFloat(response.data.discount);
    
        if (!isNaN(discountValue)) {
          this.setState({ discountPercentage: discountValue });
        } else {
          console.error('Invalid discount value received from server.');
        }
      } catch (error) {
        console.error('Failed to fetch discount value:', error);
      }
    };
  componentDidMount() {
    this.fetchDiscountValue()
    // const flightSearchData = JSON.parse(localStorage.getItem('flightSearchData'));
    // if (flightSearchData) {
    //   this.setState({ flightSearchData }, this.fetchAccessToken);
    // } else {
    //   this.setState({ error: 'No flight search data found. Please try again.' });
    // }

    const flightSearchData = JSON.parse(localStorage.getItem('flightSearchData'));
  
  if (flightSearchData) {
    // Convert start and return dates back to Date objects
    flightSearchData.start = new Date(flightSearchData.start);
    flightSearchData.return = new Date(flightSearchData.return);
    
    this.setState({ flightSearchData }, this.fetchAccessToken);
  } else {
    this.setState({ error: 'No flight search data found. Please try again.' });
  }

    this.fetchAccessToken();
  }

  // Handle page change
  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  // Get the current page's results
  getCurrentPageResults = () => {
    const { flightResults, currentPage, resultsPerPage } = this.state;
    const startIndex = (currentPage - 1) * resultsPerPage;
    const endIndex = startIndex + resultsPerPage;

    return flightResults.slice(startIndex, endIndex);
  };

  handleBookNow = (offer) => {
    const formattedFlightDetails = this.formatFlightDetails(offer);
    localStorage.setItem('selectedFlight', JSON.stringify(formattedFlightDetails));
    //window.location.href = '/customer-details';
    this.props.navigate('/customer-details')
  };
  
  formatFlightDetails = (offer) => {
    const { carrierName, itineraries, discountedPrice } = offer;
  
    const formattedItineraries = itineraries.map((itinerary, index) => ({
      itineraryNumber: index + 1,
      segments: itinerary.segments.map((segment) => ({
        carrier: segment.carrierName,
        departure: `${segment.departure.iataCode} at ${new Date(segment.departure.at).toLocaleTimeString()}`,
        arrival: `${segment.arrival.iataCode} at ${new Date(segment.arrival.at).toLocaleTimeString()}`,
        duration: segment.duration,
      })),
    }));
  
    return {
      carrier: carrierName,
      itineraries: formattedItineraries,
      price: discountedPrice + ' ' + offer.price.currency + ' / Adult',
    };
  };
  

  renderPassengerDetails = (data) => {
    const { adults, child, infants } = data;
  
    return (
      <>
        
          {adults > 0 && <span className='pass-type'>Adults: {adults}</span>}
          {child > 0 && <span className='pass-type'>Children: {child}</span>}
          {infants > 0 && <span className='pass-type'>Infants: {infants}</span>}
        
      </>
    );
  };
  render() {
    const { flightResults, error, currentPage, resultsPerPage } = this.state;
if (error) {
      return (
      <div className="flight-offers-container"><p>{error}</p></div>
      
      )
    }

    const totalPages = Math.ceil(flightResults.length / resultsPerPage);
    const currentPageResults = this.getCurrentPageResults();
    const flightSearchData = JSON.parse(localStorage.getItem("flightSearchData"));
    return (
      <div className="flight-offers-container">
      {flightResults?.length > 0 ? (
        flightResults.map((offer, index) => (
          <div key={index} className="flight-offer">
            <h3>Carrier: {offer.carrierName}</h3>
            
            {offer.itineraries.map((itinerary, iIndex) => (
              <div key={iIndex} className="itinerary">
                <h4>Itinerary {iIndex + 1}</h4>

                {itinerary.segments.map((segment, sIndex) => (
                  <div key={sIndex} className="segment">
                    <p>
                      <strong>Segment Carrier:</strong> {segment.carrierName}
                    </p>
                    <p>
                      <strong>Departure:</strong> {segment.departure.iataCode}  on {segment.departure.date} at {new Date(segment.departure.at).toLocaleTimeString()}
                    </p>
                    <p>
                      <strong>Arrival:</strong> {segment.arrival.iataCode}  on {segment.arrival.date} at {new Date(segment.arrival.at).toLocaleTimeString()}
                    </p>
                    {/* <p>
                      <strong>Duration:</strong> {segment.duration}
                    </p> */}
                  </div>
                ))}
              </div>
            ))}
            <div className="price-info">
  {offer.discountedPrice ? (
    <div>
      <span className="original-price">
        <s>{offer.price.currency} {offer.price.total}</s>
      </span>
      <span className="discounted-price">
        {offer.price.currency} {offer.discountedPrice}
      </span>
      <span className="discounted-price"> / {this.renderPassengerDetails(flightSearchData)}</span>
    </div>
  ) : (
    <span className="price">
      {offer.price.currency} {offer.price.total}
      <small> / Adult</small>
    </span>
  )}
</div>


            {/* <div className="price-info">
              <span className="price">
                {offer.price.currency} {offer.price.grandTotal}
                <small> / Adult</small>
              </span>
            </div> */}

            <div className="action">
            <button  className="button btn-small full-width bookNowBtn" onClick={() => this.handleBookNow(offer)}>Book Now</button>
              {/* <a href="#" className="button btn-small full-width">
                Book Now
                <i className="fa fa-hand-o-right" aria-hidden="true"></i>
              </a> */}
            </div>
          </div>
        ))
      ) : (
        <p>{this.state.flightCount === 0 ? (
          <p>No flights are available based on the provided information. Please adjust your search criteria and try again.</p>
      ) : (
          <p>Loading flight offers...</p>
      )}</p>
      )}
      {/* <div className="pagination">
          {Array.from({ length: totalPages }).map((_, pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => this.handlePageChange(pageNumber + 1)}
              className={`page-button ${currentPage === pageNumber + 1 ? 'active' : ''}`}
            >
              {pageNumber + 1}
            </button>
          ))}
        </div> */}
    </div>
    );
  }
}

export default withNavigate(FlightSearchResults);
