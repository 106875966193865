import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import img16 from '../images/payment_cards.png';
class Footer extends Component {
  render() {
    const phoneNumber = '+447360270458';
    return (
      <>
        <div
    data-elementor-type="footer"
    data-elementor-id={173}
    className="elementor elementor-173 elementor-location-footer"
    data-elementor-post-type="elementor_library"
  >
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-b095fbe elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="b095fbe"
      data-element_type="section"
      data-settings='{"background_background":"gradient"}'
    >
      <div className="elementor-container elementor-column-gap-default">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-569ccc8"
          data-id="569ccc8"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <section
              className="elementor-section elementor-inner-section elementor-element elementor-element-708d266 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="708d266"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-8d0049c"
                  data-id="8d0049c"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-82a0e46 elementor-widget elementor-widget-heading"
                      data-id="82a0e46"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h3 className="elementor-heading-title elementor-size-default">
                          About Us
                        </h3>{" "}
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-84cf729 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                      data-id="84cf729"
                      data-element_type="widget"
                      data-widget_type="divider.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-divider">
                          <span className="elementor-divider-separator"></span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-14e57b7 elementor-widget elementor-widget-text-editor"
                      data-id="14e57b7"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        <p>
                          UK Travel Route is your one-stop online travel agency
                          offering international and domestic flights from all
                          major airports of UK on all major airlines, vehicle
                          hire options from top car rental companies and hotel
                          reservations book able for properties on worldwide
                          destinations.
                        </p>{" "}
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-7789ea8 elementor-widget elementor-widget-image"
                      data-id="7789ea8"
                      data-element_type="widget"
                      data-widget_type="image.default"
                    >
                      <div className="elementor-widget-container">
                        <img
                          width={194}
                          height={28}
                          src={img16}
                          className="attachment-full size-full wp-image-57"
                          alt=""
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-e9cbe51"
                  data-id="e9cbe51"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-2ae7856 elementor-widget elementor-widget-heading"
                      data-id="2ae7856"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h3 className="elementor-heading-title elementor-size-default">
                          Quick Links
                        </h3>{" "}
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-260b89c elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                      data-id="260b89c"
                      data-element_type="widget"
                      data-widget_type="divider.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-divider">
                          <span className="elementor-divider-separator"></span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-8a8a810 elementor-align-left elementor-mobile-align-center elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                      data-id="8a8a810"
                      data-element_type="widget"
                      data-widget_type="icon-list.default"
                    >
                      <div className="elementor-widget-container">
                        <ul className="elementor-icon-list-items">
                          <li className="elementor-icon-list-item">
                          <a href="/about"><span className="elementor-icon-list-icon">
                                <svg
                                  aria-hidden="true"
                                  className="e-font-icon-svg e-fas-caret-right"
                                  viewBox="0 0 192 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" />
                                </svg>{" "}
                              </span>
                              <span className="elementor-icon-list-text">
                                About Us
                              </span></a>
                            {/* <a href="about-us/index.html">
                              <span className="elementor-icon-list-icon">
                                <svg
                                  aria-hidden="true"
                                  className="e-font-icon-svg e-fas-caret-right"
                                  viewBox="0 0 192 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" />
                                </svg>{" "}
                              </span>
                              <span className="elementor-icon-list-text">
                                About Us
                              </span>
                            </a> */}
                          </li>
                          <li className="elementor-icon-list-item">
                          <a href="/contact"><span className="elementor-icon-list-icon">
                                <svg
                                  aria-hidden="true"
                                  className="e-font-icon-svg e-fas-caret-right"
                                  viewBox="0 0 192 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" />
                                </svg>{" "}
                              </span>
                              <span className="elementor-icon-list-text">
                                Contact Us
                              </span></a>
                            {/* <a href="contact-us/index.html">
                              <span className="elementor-icon-list-icon">
                                <svg
                                  aria-hidden="true"
                                  className="e-font-icon-svg e-fas-caret-right"
                                  viewBox="0 0 192 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" />
                                </svg>{" "}
                              </span>
                              <span className="elementor-icon-list-text">
                                Contact Us
                              </span>
                            </a> */}
                          </li>
                          {/* <li className="elementor-icon-list-item">
                            <a href="#">
                              <span className="elementor-icon-list-icon">
                                <svg
                                  aria-hidden="true"
                                  className="e-font-icon-svg e-fas-caret-right"
                                  viewBox="0 0 192 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" />
                                </svg>{" "}
                              </span>
                              <span className="elementor-icon-list-text">
                                Request Callback
                              </span>
                            </a>
                          </li> */}
                          <li className="elementor-icon-list-item">
                          <a href="/privacy-policy"> 
                          <span className="elementor-icon-list-icon">
                                <svg
                                  aria-hidden="true"
                                  className="e-font-icon-svg e-fas-caret-right"
                                  viewBox="0 0 192 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" />
                                </svg>{" "}
                              </span>
                              <span className="elementor-icon-list-text">
                                Privacy Policy
                              </span>
                          </a>
                            {/* <a href="privacy-policy/index.html">
                              <span className="elementor-icon-list-icon">
                                <svg
                                  aria-hidden="true"
                                  className="e-font-icon-svg e-fas-caret-right"
                                  viewBox="0 0 192 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" />
                                </svg>{" "}
                              </span>
                              <span className="elementor-icon-list-text">
                                Privacy Policy
                              </span>
                            </a> */}
                          </li>
                          <li className="elementor-icon-list-item">
                          <a href="/terms-and-conditions"> <span className="elementor-icon-list-icon">
                                <svg
                                  aria-hidden="true"
                                  className="e-font-icon-svg e-fas-caret-right"
                                  viewBox="0 0 192 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" />
                                </svg>{" "}
                              </span>
                              <span className="elementor-icon-list-text">
                                Terms &amp; Conditions
                              </span></a>
                            {/* <a href="terms-conditions/index.html">
                              <span className="elementor-icon-list-icon">
                                <svg
                                  aria-hidden="true"
                                  className="e-font-icon-svg e-fas-caret-right"
                                  viewBox="0 0 192 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" />
                                </svg>{" "}
                              </span>
                              <span className="elementor-icon-list-text">
                                Terms &amp; Conditions
                              </span>
                            </a> */}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-f0163f8"
                  data-id="f0163f8"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-4f811af elementor-widget elementor-widget-heading"
                      data-id="4f811af"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h3 className="elementor-heading-title elementor-size-default">
                          Get in Touch
                        </h3>{" "}
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-465603f elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                      data-id="465603f"
                      data-element_type="widget"
                      data-widget_type="divider.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-divider">
                          <span className="elementor-divider-separator"></span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-4b80727 elementor-align-left elementor-mobile-align-center elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                      data-id="4b80727"
                      data-element_type="widget"
                      data-widget_type="icon-list.default"
                    >
                      <div className="elementor-widget-container">
                        <ul className="elementor-icon-list-items">
                          <li className="elementor-icon-list-item">
                            <a href="#">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="icon icon-envelope2"
                                />{" "}
                              </span>
                              <span className="elementor-icon-list-text">
                                admin@uktravelroute.co.uk
                              </span>
                            </a>
                          </li>
                          <li className="elementor-icon-list-item">
                            <a href="#">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="icon icon-phone1"
                                />{" "}
                              </span>
                              <span className="elementor-icon-list-text">
                              0203 983 7173
                              </span>
                            </a>
                          </li>
                          <li className="elementor-icon-list-item">
                            <a href="#">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="icon icon-whatsapp-2"
                                />{" "}
                              </span>
                              <span className="elementor-icon-list-text">
                               

                                <a
          href={`https://wa.me/${phoneNumber}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: '#25D366' }}
        >
         Send WhatsApp
        </a>
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-cfa5122 elementor-shape-circle e-grid-align-left e-grid-align-mobile-center elementor-grid-0 elementor-widget elementor-widget-social-icons"
                      data-id="cfa5122"
                      data-element_type="widget"
                      data-widget_type="social-icons.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-social-icons-wrapper elementor-grid">
                          <span className="elementor-grid-item">
                            <a
                              className="elementor-icon elementor-social-icon elementor-social-icon-icon-facebook elementor-animation-shrink elementor-repeater-item-8dd35b8"
                              href="#"
                              target="_blank"
                            >
                              <span className="elementor-screen-only">
                                Icon-facebook
                              </span>
                              <i className="icon icon-facebook" />{" "}
                            </a>
                          </span>
                          <span className="elementor-grid-item">
                            <a
                              className="elementor-icon elementor-social-icon elementor-social-icon-x-twitter elementor-animation-shrink elementor-repeater-item-eabbe79"
                              href="#"
                              target="_blank"
                            >
                              <span className="elementor-screen-only">
                                X-twitter
                              </span>
                              <svg
                                className="e-font-icon-svg e-fab-x-twitter"
                                viewBox="0 0 512 512"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                              </svg>{" "}
                            </a>
                          </span>
                          <span className="elementor-grid-item">
                            <a
                              className="elementor-icon elementor-social-icon elementor-social-icon-linkedin-in elementor-animation-shrink elementor-repeater-item-c300e54"
                              href="#"
                              target="_blank"
                            >
                              <span className="elementor-screen-only">
                                Linkedin-in
                              </span>
                              <svg
                                className="e-font-icon-svg e-fab-linkedin-in"
                                viewBox="0 0 448 512"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                              </svg>{" "}
                            </a>
                          </span>
                          <span className="elementor-grid-item">
                            <a
                              className="elementor-icon elementor-social-icon elementor-social-icon-pinterest elementor-animation-shrink elementor-repeater-item-deb98fd"
                              href="#"
                              target="_blank"
                            >
                              <span className="elementor-screen-only">
                                Pinterest
                              </span>
                              <svg
                                className="e-font-icon-svg e-fab-pinterest"
                                viewBox="0 0 496 512"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z" />
                              </svg>{" "}
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div
              className="elementor-element elementor-element-a4777ff elementor-widget elementor-widget-spacer"
              data-id="a4777ff"
              data-element_type="widget"
              data-widget_type="spacer.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-spacer">
                  <div className="elementor-spacer-inner" />
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-48c1e64 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
              data-id="48c1e64"
              data-element_type="widget"
              data-widget_type="divider.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-divider">
                  <span className="elementor-divider-separator"></span>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-454c8f5 elementor-widget elementor-widget-text-editor"
              data-id="454c8f5"
              data-element_type="widget"
              data-widget_type="text-editor.default"
            >
              <div className="elementor-widget-container">
                <p>Copyright ©2024, All Rights Reserved.</p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
      </>
    );
  }
}

export default Footer;
