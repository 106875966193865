import React, { Component } from 'react';
import axios from 'axios';
import '../css/Contact.css'
class Contact extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      subject: 'Subject',
      message: '',
      errors: {},
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  validateForm = () => {
    console.log("validate form")
    const { name, email, phone } = this.state;
    const errors = {};

    // Validate Name
    if (!name.trim()) {
      errors.name = 'Name is required';
    }

    // Validate Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      errors.email = 'Email is required';
    } else if (!emailRegex.test(email)) {
      errors.email = 'Invalid email address';
    }

    // Validate Phone (International Format)
    const phoneRegex = /^\+?\d{10,15}$/;
    if (!phone.trim()) {
      errors.phone = 'Phone number is required';
    } else if (!phoneRegex.test(phone)) {
      errors.phone = 'Invalid phone number';
    }

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  handleSubmit = async (e) => {
    console.log("handle submit")
    e.preventDefault();
    //if (!this.validateForm()) return;

    const { name, email, phone, subject, message } = this.state;

    // Prepare data to send
   
    const formData = JSON.stringify({
      name: name,
      email: email,
      phone: phone,
      subject: subject,
      message: message
    })

    try {
     
      const response = await axios.post('https://api.uktravelroute.co.uk/send-email', {
        name: name,
        email: email,
        phone: phone,
        subject: subject,
        message: message
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log("response status-===",response.status)
      if (response.status === 200) {
        alert('Your message has been sent successfully!');
        console.log(response.data); // Optionally log the response data
      } else {
        throw new Error('Failed to send email');
      }
    //   fetch('https://api.uktravelroute.co.uk/send-email', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({
    //       name: name,
    //       email: email,
    //       phone: phone,
    //       subject: subject,
    //       message: message
    //     }),
    //   })
    //     .then(response => {
    //       if (response.ok) {
    //         alert('Your message has been sent successfully!');
    //         return response.text();
    //       }
    //       throw new Error('Failed to send email');
    //     })
    //     .then(result => console.log(result))
    //     .catch(error => console.error('Error:', error));
      
    // } catch (error) {
    //   console.error('Failed to send message:', error);
    //   alert('Failed to send your message. Please try again later.');
    // }
  }
  catch (error) {
      console.error('Failed to send message:', error);
      alert('Failed to send your message. Please try again later.');
    }
  }
  render() {
    const { name, email, phone, subject, message, errors } = this.state;
    return (
      <>
      <div
  data-elementor-type="wp-page"
  data-elementor-id={238}
  className="elementor elementor-238"
  data-elementor-post-type="page"
>
  <section
    className="elementor-section elementor-top-section elementor-element elementor-element-4cd2316 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
    data-id="4cd2316"
    data-element_type="section"
  >
    <div className="elementor-background-overlay" />
    <div className="elementor-container elementor-column-gap-default">
      <div
        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-fe2c6ac"
        data-id="fe2c6ac"
        data-element_type="column"
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
            className="elementor-element elementor-element-67a83de elementor-widget elementor-widget-heading"
            data-id="67a83de"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h3 className="elementor-heading-title elementor-size-default">
                TALK TO US
              </h3>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-120d9d1 elementor-widget elementor-widget-heading"
            data-id="120d9d1"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Get in Touch With Us
              </h2>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-4ef331c elementor-mobile-align-left elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
            data-id="4ef331c"
            data-element_type="widget"
            data-widget_type="icon-list.default"
          >
            <div className="elementor-widget-container">
              <ul className="elementor-icon-list-items">
                <li className="elementor-icon-list-item">
                  <span className="elementor-icon-list-icon">
                    <i aria-hidden="true" className="icon icon-envelope2" />{" "}
                  </span>
                  <span className="elementor-icon-list-text">
                    {" "}
                    admin@uktravelroute.co.uk
                  </span>
                </li>
                <li className="elementor-icon-list-item">
                  <span className="elementor-icon-list-icon">
                    <i aria-hidden="true" className="icon icon-phone1" />{" "}
                  </span>
                  <span className="elementor-icon-list-text">
                    {" "}
                    0203 983 7173
                  </span>
                </li>
                <li className="elementor-icon-list-item">
                  <span className="elementor-icon-list-icon">
                    <i aria-hidden="true" className="icon icon-map-marker" />{" "}
                  </span>
                  <span className="elementor-icon-list-text">
                  Suite 200i Cumberland House, 80 Scrubs Lane, London, England, NW10 6RF
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-f5c6f55"
        data-id="f5c6f55"
        data-element_type="column"
        data-settings='{"background_background":"classic"}'
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
            className="elementor-element elementor-element-e9df722 elementor-widget elementor-widget-heading"
            data-id="e9df722"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Any Question? Feel Free to Contact
              </h2>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-2a2aed7 elementor-button-align-stretch elementor-widget elementor-widget-form"
            data-id="2a2aed7"
            data-element_type="widget"
            data-settings='{"step_next_label":"Next","step_previous_label":"Previous","button_width":"100","step_type":"number_text","step_icon_shape":"circle"}'
            data-widget_type="form.default"
          >
            <div className="elementor-widget-container">
              <form className="elementor-form" method="post" onSubmit={this.handleSubmit}>
        <input type="hidden" name="post_id" defaultValue={238} />
        <input type="hidden" name="form_id" defaultValue="2a2aed7" />
        <input type="hidden" name="referer_title" defaultValue="Contact Us" />
        <input type="hidden" name="queried_id" defaultValue={238} />

        <div className="elementor-form-fields-wrapper elementor-labels-">
          {/* Name Field */}
          <div className="elementor-field-group elementor-col-50">
            <input
              type="text"
              name="name"
              className="elementor-field"
              placeholder="Name"
              value={name}
              onChange={this.handleInputChange}
              required
            />
            {errors.name && <p className="error">{errors.name}</p>}
          </div>

          {/* Email Field */}
          <div className="elementor-field-group elementor-col-50">
            <input
              type="email"
              name="email"
              className="elementor-field"
              placeholder="Email"
              value={email}
              onChange={this.handleInputChange}
              required
            />
            {errors.email && <p className="error">{errors.email}</p>}
          </div>

          {/* Phone Number Field */}
          <div className="elementor-field-group elementor-col-50">
            <input
              type="text"
              name="phone"
              className="elementor-field"
              placeholder="Phone Number"
              value={phone}
              onChange={this.handleInputChange}
              required
            />
            {errors.phone && <p className="error">{errors.phone}</p>}
          </div>

          {/* Subject Field */}
          <div className="elementor-field-group elementor-col-50">
            <select
              name="subject"
              className="elementor-field"
              value={subject}
              onChange={this.handleInputChange}
            >
              <option value="Subject">Subject</option>
              <option value="General">General</option>
              <option value="Complaint">Complaint</option>
              <option value="Suggestion">Suggestion</option>
            </select>
          </div>

          {/* Message Field */}
          <div className="elementor-field-group elementor-col-100">
            <textarea
              name="message"
              className="elementor-field"
              rows={4}
              placeholder="Message"
              value={message}
              onChange={this.handleInputChange}
            />
          </div>

          {/* Submit Button */}
          <div className="elementor-field-group elementor-col-100">
            <button type="submit" className="elementor-button">
              Send
            </button>
          </div>
        </div>
      </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

      </>
    )
  }
}

export default Contact;
